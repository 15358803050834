/// <reference types="./types" />
/// <reference types="./settings" />

// inside iif to avoid polluting global namespace
(() => {
  const TERRIFIC_TAG_NAME = 'terrific-live-session';
  const self = document.querySelector('script[src*="register-embeddable"]');
  const TERRIFIC_LIVE_API = 'https://terrific.live';
  const DEFAULT_BASE_API_URL =
    (self?.getAttribute('src')?.replace(/\/[^/]+$/, '') as ValidBaseUrl) ?? TERRIFIC_LIVE_API;
  const BACKEND_FUNCTION_NAME = 'getEmbeddedInnerHtml';

  const isDebugMode = () => {
    return localStorage.getItem('terrific_debug') === 'true';
  };

  const scriptTag = document.currentScript as HTMLScriptElement;
  const fontFamily: string = scriptTag.getAttribute('font-family') || '';

  const localhost = (window.terrificLiveSettings ?? {}).baseApiUrl?.includes('localhost');

  const consoleProxy = (
    message: any,
    level: 'log' | 'warn' | 'info' | 'error' | 'debug' = 'log'
  ) => {
    if (level === 'error') {
      console.error(message, 'error');
      return;
    } else if (isDebugMode()) {
      console[level](message);
    }
  };

  const defaultSettings: TerrificLiveSettings = {
    baseApiUrl: DEFAULT_BASE_API_URL,
    registerTerrificWebComponent: true,
  };
  // embed this via <script src="https://terrific.live/register-embeddable.js"></script>
  // and before this script add the following script tag or embed your customer custom script:
  // <script>
  // window.terrificLiveSettings = {
  //   baseApiUrl: 'https://terrific.live',
  //   registerTerrificWebComponent: false,
  //   goToCheckoutConfig: {
  //     cartProductTransform: {
  //       variantSku: 'sku',
  //       quantity: 'qty',
  //     },
  //     clearCurrentCart: false,
  //     goToCheckoutHandler: async (data: {cartProducts: TransformedCartProduct[]}) => {
  //       // site logic
  //     },
  //   },
  // };
  // </script>

  class TerrificLiveComponent extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({mode: 'open'});
    }

    async connectedCallback() {
      const id = this.getAttribute('id');
      const sessionId = this.getAttribute('sessionId');
      consoleProxy(
        `[terrific-${
          window.terrificLiveSettings?.customerName || 'live'
        }]: connected callback for ${id ?? sessionId}`
      );
      const behavior = this.getAttribute('behavior');
      const view = this.getAttribute('view');
      const blockTopNavigation = this.getAttribute('blockTopNavigation');

      verifySettings();

      const baseApiUrl = (window.terrificLiveSettings ?? {}).baseApiUrl ?? DEFAULT_BASE_API_URL;
      const shopPageUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

      const getScreenHeight = () => {
        const screenHeight = window.innerHeight;
        const headerClasses = ['.s-header', '.navbar', '.s-mobile-navbar', '.header'];
        let headerHeight = 0;

        for (const headerClass of headerClasses) {
          const header = document.querySelector(headerClass) as HTMLElement;
          if (header) {
            const elementHeight = header.getBoundingClientRect().height;
            if (elementHeight > 0) {
              headerHeight += elementHeight;
            }
          }
        }

        return screenHeight - headerHeight;
      };

      const response = await fetch(
        `${
          localhost ? 'http://localhost:5001/terrific-devs/us-central1' : baseApiUrl
        }/${BACKEND_FUNCTION_NAME}?id=${id}&sessionId=${sessionId}&source=${encodeURIComponent(
          window.location.href
        )}&terrificUrl=${baseApiUrl}&view=${view}&behavior=${behavior}&shopPageUrl=${encodeURIComponent(
          shopPageUrl
        )}&screenHeight=${getScreenHeight()}px&isDebugMode=${
          isDebugMode() ? 'true' : 'false'
        }&fontFamily=${fontFamily}`
      ).catch((error) => {
        consoleProxy(
          `[terrific-${
            window.terrificLiveSettings?.customerName || 'live'
          }]: error fetching ${id} on ${baseApiUrl}/${BACKEND_FUNCTION_NAME} ${error}`,
          'error'
        );
      });
      if (!response) return;
      const data = (await response.json().catch((error) => {
        consoleProxy(
          `[terrific-${
            window.terrificLiveSettings?.customerName || 'live'
          }]: error parsing response for ${id} ${error}`,
          'error'
        );
      })) as {html: string; codeToExecute: string} | undefined;

      if (!data) return;

      if (!this.shadowRoot) {
        throw new Error('No shadow root');
      }

      if (blockTopNavigation === 'true') {
        const sandboxAttributes = [
          'allow-forms',
          'allow-modals',
          'allow-orientation-lock',
          'allow-pointer-lock',
          'allow-popups',
          'allow-popups-to-escape-sandbox',
          'allow-presentation',
          'allow-same-origin',
          'allow-scripts',
          // 'allow-top-navigation', // this is the one we want to block
        ];
        data.html = data.html.replace(
          /<iframe([^>]*)>/,
          `<iframe sandbox="${sandboxAttributes.join(' ')}"$1>`
        );
      }

      this.shadowRoot.innerHTML = data.html;

      // Execute the custom script within the context of this component
      try {
        new Function('shadowRoot', data.codeToExecute).call(this, this.shadowRoot);
      } catch (error) {
        consoleProxy(
          `[terrific-${
            window.terrificLiveSettings?.customerName || 'live'
          }]: Error executing custom script: ${error}`,
          'error'
        );
      }
    }
  }

  verifySettings();

  if (window.terrificLiveSettings?.registerTerrificWebComponent) {
    consoleProxy(
      `[terrific-${
        window.terrificLiveSettings?.customerName || 'live'
      }]: registering web component ${TERRIFIC_TAG_NAME}`
    );
    if (!customElements.get(TERRIFIC_TAG_NAME)) {
      customElements.define(TERRIFIC_TAG_NAME, TerrificLiveComponent);
    }
    consoleProxy(
      `[terrific-${
        window.terrificLiveSettings?.customerName || 'live'
      }]: web component ${TERRIFIC_TAG_NAME} registered`
    );
  }

  if (!window.terrificLive) {
    window.terrificLive = class TerrificLive {
      private static waitingMessages: {messageType: MessageType; data: any}[] = [];

      static listeners: {messageType: MessageType; func: (...args: unknown[]) => unknown}[] = [];
      static postMessage(messageType: MessageType, data: any) {
        consoleProxy(
          `[terrific-${
            window.terrificLiveSettings?.customerName || 'live'
          }]: handling event ${messageType} - ${JSON.stringify(data)}`
        );
        let hasActiveListener = false;
        this.listeners.forEach((listener) => {
          try {
            if (listener.messageType === messageType) {
              consoleProxy(
                `[terrific-${
                  window.terrificLiveSettings?.customerName || 'live'
                }]: calling listener for ${messageType} ${listener}`
              );
              hasActiveListener = true;
              consoleProxy(
                `[terrific-${
                  window.terrificLiveSettings?.customerName || 'live'
                }]: calling listener for ${messageType} ${JSON.stringify(
                  window.terrificLiveSettings?.cartSyncConfig
                )}`
              );
              listener.func(messageType, 'data' in data ? data.data : data);
            }
          } catch (error) {
            consoleProxy(
              `[terrific-${
                window.terrificLiveSettings?.customerName || 'live'
              }]: error calling listener for ${messageType} ${error}`,
              'error'
            );
          }
        });
        if (!this.listeners.length || !hasActiveListener) {
          consoleProxy(
            `[terrific-${
              window.terrificLiveSettings?.customerName || 'live'
            }]: no listener for ${messageType}`
          );
          this.waitingMessages.push({messageType, data});
          return;
        }
      }
      static addEventListener(messageType: MessageType, listener: (...args: unknown[]) => unknown) {
        this.listeners.push({messageType, func: listener});
        this.waitingMessages.forEach((message) => {
          if (message.messageType === messageType) {
            listener(message.messageType, message.data);
          }
        });
        this.waitingMessages = this.waitingMessages.filter(
          (message) => message.messageType !== messageType
        );
      }
    };

    if (window.terrificLiveSettings?.goToCheckoutConfig) {
      window.terrificLive.addEventListener('GoToCheckout', (_: any, data: any) =>
        terrificSettingsGoToCheckoutHandler(data)
      );
    }

    if (window.terrificLiveSettings?.cartSyncConfig) {
      consoleProxy(
        `[terrific-${
          window.terrificLiveSettings?.customerName || 'live'
        }]: cart sync config found ${JSON.stringify(window.terrificLiveSettings?.cartSyncConfig)}`
      );

      window.terrificLive.addEventListener('cart-change-notification', (_: any, data: any) => {
        consoleProxy(
          `[terrific-${
            window.terrificLiveSettings?.customerName || 'live'
          }]: cart change notification ${JSON.stringify(data)}`
        );
        const action = {
          add: 'addProductToCart',
          updateUp: 'updateProductInCart',
          updateDown: 'updateProductInCart',
          remove: 'removeProductFromCart',
        }[data.type as 'add' | 'updateUp' | 'updateDown' | 'remove'];

        if (!action) return;

        window.terrificLiveSettings?.cartSyncConfig?.cartSyncHandler({
          action,
          cartProducts: [data.product],
        });
      });
    }
    window.terrificLive.addEventListener('RefreshEmbedded', (_: any) => refreshEmbeddedHandler());
    window.terrificLive.addEventListener('toggleEmbeddedSizeDesktop', (_: any) =>
      toggleEmbeddedSizeHandler(false)
    );
    window.terrificLive.addEventListener('toggleEmbeddedSizeMobile', (_: any) =>
      toggleEmbeddedSizeHandler(true)
    );
    window.terrificLive.addEventListener('sessionFinishedLoading', (_: any) =>
      onSessionFinishedLoadingHandler()
    );

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('terrific-live-event') === 'GoToCheckout' && urlParams.get('data')) {
      const data = JSON.parse(window.atob(urlParams.get('data') ?? ''));
      requestAnimationFrame(() => postMessage('GoToCheckout', data as any));
    }
    if (urlParams.get('terrific-live-event') === 'GoToCheckout' && urlParams.get('orderId')) {
      const orderId = JSON.parse(window.atob(urlParams.get('orderId') ?? ''));

      fetch(`${window.terrificLiveSettings?.baseApiUrl ?? DEFAULT_BASE_API_URL}/order`, {
        body: JSON.stringify({data: {orderId}}),
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'exclude',
        },
      })
        .then((res) => res.json())
        .then((res: any) => {
          const data = res.result;
          requestAnimationFrame(() => postMessage('GoToCheckout', data));
        });
    }
  }

  async function refreshEmbeddedHandler() {
    const terrificElement = document.querySelectorAll(
      'terrific-live-session'
    )[0] as TerrificLiveComponent; // TODO: change [0] to specific id in the future after we refactor the id / embed-id.
    terrificElement?.connectedCallback();
  }

  function toggleEmbeddedSizeHandler(isMobile: boolean) {
    const embeddedElement = document.getElementsByTagName(
      'terrific-live-session'
    )[0] as HTMLElement;
    if (embeddedElement) {
      const currentHeight = embeddedElement.style.height;
      if (currentHeight !== '' && currentHeight === '100%') {
        embeddedElement.style.height = ''; // reseting to default by the :host css
        embeddedElement.style.width = ''; // reseting to default by the :host css
        embeddedElement.style.borderRadius = '';
      } else if (isMobile) {
        embeddedElement.style.height = '100%';
        embeddedElement.style.width = '100%';
        embeddedElement.style.borderRadius = '0px'; // no round edges for mobile on full screen
      }
      const isMaximized = embeddedElement.classList.contains('maximized');
      if (isMaximized) {
        embeddedElement.classList.remove('maximized');
        embeddedElement.classList.add('minimized');
        document.body.style.pointerEvents = 'all'; // the backend script sets this to none when it maximizes, so we need to reset it to 'all'
      } else {
        embeddedElement.classList.add('maximized');
        embeddedElement.classList.remove('minimized');
      }
    }
  }

  function onSessionFinishedLoadingHandler() {
    const embeddedElement = document.getElementsByTagName(
      'terrific-live-session'
    )[0] as HTMLElement;
    const button = embeddedElement?.shadowRoot?.getElementById(
      'terrific-minimize-button'
    ) as HTMLButtonElement;
    if (button) {
      button.disabled = false;
    }
  }

  async function terrificSettingsGoToCheckoutHandler(data: ParamsData) {
    const products =
      data instanceof Array
        ? data
        : 'products' in data
        ? data.products
        : 'products' in (data.order ?? {})
        ? data.order!.products
        : [];

    const config = window.terrificLiveSettings?.goToCheckoutConfig;

    const productTransformer = config?.cartProductTransform;

    const productsToCheckout = products.map((product: any) => {
      if (!productTransformer) return product;

      const convertedProduct: object = {};
      flatMap(Object.keys(product), (key) => {
        const value = product[key]; // es 2017 added Object.entries and we are aiming for es2015
        if (!(key in productTransformer)) return [{key, value}];
        if (typeof value === 'object' && value !== null)
          return Object.keys(value)
            .map((subKey) => ({
              key: `${key}.${subKey}`,
              value: value[subKey],
            }))
            .concat({key, value}); // if the value is an object, we want to keep the original key: value pair
        return [{key, value}];
      }).forEach(({key, value}) => {
        if (!(key in productTransformer)) return;
        const newKeyOrKeys: string | string[] = (productTransformer as any)[key];
        if (Array.isArray(newKeyOrKeys)) {
          newKeyOrKeys.forEach((newKey) => {
            setAtPath(convertedProduct, newKey, value);
          });
        } else if (typeof newKeyOrKeys === 'string') {
          setAtPath(convertedProduct, newKeyOrKeys, value);
        }
      });
      return convertedProduct;
    });

    return (
      (await config?.goToCheckoutHandler({cartProducts: productsToCheckout})) ?? productsToCheckout
    );
  }

  function postMessage(messageType: MessageType, data: any) {
    if (!data) return;
    window.terrificLive?.postMessage(messageType, {
      data,
    });
  }

  function verifySettings() {
    window.terrificLiveSettings = window.terrificLiveSettings
      ? Object.assign(defaultSettings, window.terrificLiveSettings)
      : defaultSettings;
  }

  window.addEventListener('message', (event) => {
    if (event.data && typeof event.data === 'object' && event.data.type === 'terrific-live-event') {
      window.terrificLive?.postMessage(event.data.messageType, event.data);
      event.stopImmediatePropagation();
    } else if (event.data && typeof event.data === 'object' && event.data.type === 'terrific-uid') {
      localStorage.setItem('terrific-uid', event.data.uid);
      event.stopImmediatePropagation();
    } else if (
      event.data &&
      typeof event.data === 'object' &&
      event.data.type === 'pic-in-pic-status'
    ) {
      localStorage.setItem('pic-in-pic-status', event.data.status);
      event.stopImmediatePropagation();
    }
  });

  function flatMap<T, U>(
    array: T[],
    callbackfn: (value: T, index: number, array: T[]) => U[]
  ): U[] {
    return ([] as U[]).concat(...array.map(callbackfn));
  }

  function setAtPath(obj: any, path: string, value: any) {
    const keys = path.split('.');
    const lastKey = keys.pop()!;
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] ?? {}), obj);
    lastObj[lastKey] = value;
  }
})();
